.main {
  width: 100%;
  height: 600px;
}

.main .time {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  color: white;
  font-family: monospace;
  max-width: 250px;
  padding: 10px;
}

.main .time table {
  font-size: 20px;
}

.main .time table img {
  height: 14px;
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  margin-top: 5px;
}

.main .controls {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  color: white;
  font-family: monospace;
  max-width: 250px;
  padding: 10px;
}

.main .title {
  text-align: center;
  color: white;
  font-family: monospace;
  margin-top: 15vh;
}

.main .title .head {
  font-size: 43px;
}

.main .gallery {
  text-align: center;
}

.main .gallery a {
  text-decoration: none;
  color: white;
  font-family: monospace;
}

.main .gallery .page {
  display: inline-block;
  width: 500px;
  padding: 0 30px;
  max-width: 90%;
  margin-top: 50px;
}

.main .gallery .page .name {
  font-size: 20px;
  margin-top: 5px;
}

.main .gallery .page img {
  max-width: 100%;
  transition: transform 0.2s;
}

.main .gallery .page img:hover {
  transform: scale(1.05);
}

.main .git {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  color: white;
  font-family: monospace;
  max-width: 250px;
  padding: 10px;
}

.main .git img {
  transition: transform 0.2s;
  width: 50px;
}

.main .git img:hover {
  transform: scale(1.05);
}

.main .about {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: white;
  font-family: monospace;
  max-width: 250px;
  padding: 10px;
}

.main .about a {
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  color: white;
}

.main .controls .plotparameters .plotrow {
  margin-bottom: 10px;
  text-align: right;
}

.main .controls .plotparameters .plotrow table.color-values {
  width: 106%;
  font-size: 10px;
  margin-left: -2%;
}

.main .controls .plotparameters .plotrow button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  width: 25px;
  margin-left: 5px;
  cursor: pointer;
}

.main .controls .plotparameters .plotrow button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.main .controls .plotparameters .plotrow input {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  width: 70px;
}

.main .controls .plotparameters table.scale-box {
  border-collapse: collapse;
  display: inline-block;
  margin-bottom: -4px;
  margin-left: 10px;
}

.main .controls .plotparameters table.scale-box td {
  border: 1px solid white;
}

.main .controls .plotparameters table.scale-box input {
  color: white;
  background: transparent;
  border: 0;
  width: 44px;
  padding: 0;
}

.threeviewer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: black;
}

.threeviewer canvas {
  outline: none;
  border: none;
}

.threeviewer .loading {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 20%;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  box-sizing: border-box;
}

.fade-out {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
