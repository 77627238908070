.notfound {
  margin-top: 30%;
  color: white;
  font-family: monospace;
  text-align: center;
}

.notfound a {
  color: white;
}

.url {
  display: inline-block;
  font-weight: 700;
}
